import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeAuthenticatedRequest } from '../config/api';
import '../styles/Login.css';
import Spinner from '../Modals/Spinner';

function Login({ setUser, showModal }) {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  function handleLogin(event) {
    event.preventDefault();
    setIsLoading(true);
    setError(''); // Clear previous errors

    makeAuthenticatedRequest(false, 'post', '/users/login', { email, password })
      .then(response => {
        setTimeout(() => {
          navigate('/dashboard');
          const data = response.data;
          if (data.id && data.token && data.refreshToken) {
            localStorage.setItem('token', data.token);
            localStorage.setItem('refreshToken', data.refreshToken);
            localStorage.setItem('id', data.id);
            // Fetch user data
            makeAuthenticatedRequest(true, 'get', `/users/${data.id}`)
              .then(userResponse => {
                setUser(userResponse.data);
                setTimeout(() => navigate('/dashboard'), 2000);
              })
              .catch(fetchError => {
                setError("Failed to fetch user details.");
                setIsLoading(false);
              });
          }
        }, 2000);
      })
      .catch(error => {
        setError(
          error.response && error.response.status === 404 ? 
          "User Not Registered, Please Register" : 
          error.response && error.response.status === 401 ? 
          "Invalid Password" : "Failed to Login, Please try again Later");
        setIsLoading(false);
      });
  }

  function handleRegister() {
    navigate('/register');
  }

  function handleForgotPassword() {
    navigate('/forgot-password');
  }

  function handleGoogleLogin() {
    window.location.href = `${process.env.REACT_APP_ENV === "development" ? process.env.REACT_APP_BACKEND_DEVELOPMENT : process.env.REACT_APP_BACKEND_PRODUCTION}/api/users/auth/google`;
  }

  return (
    <div className="login-container">
      <h2>Login</h2>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleLogin}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={e => setEmail((e.target.value))}
          required
          className={error ? 'input-error' : ''}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          required
          className={error ? 'input-error' : ''}
        />
        {isLoading ? (
          <div className="loader">Logging in...</div>
        ) : (
          <button type="submit">Login</button>
        )}
      </form>
      <button onClick={handleGoogleLogin} className="google-btn">
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg"
          alt="Google logo"
        />
        Login with Google
      </button>
      <button onClick={handleRegister} className="secondary-btn">
        Register
      </button>
      <p className="forgot-password" onClick={handleForgotPassword}>
        <a href="#">Forgot Password?</a>
      </p>
      {isLoading && <Spinner message="Logging in..." />}
    </div>
  );
}

export default Login;