import React, { useState, useEffect } from 'react';
import { makeAuthenticatedRequest } from '../config/api';
import '../styles/AI.css';

function AI({ user, showModal }) {
    const [loading, setLoading] = useState(false);
    const [modelStatus, setModelStatus] = useState('checking');
    const [analysis, setAnalysis] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (user?.id) {
            checkTrainingStatus();
        }
    }, [user]);

    const checkTrainingStatus = async () => {
        try {
            const response = await makeAuthenticatedRequest(
                true,
                'get',
                `/ai/training-status/${user.id}`
            );
            setModelStatus(response.data.status);
            
            if (response.data.status === 'Model trained and available') {
                analyzeFinances();
            }
        } catch (error) {
            handleError('Error checking model status');
        }
    };

    const handleTrainAI = async () => {
        setLoading(true);
        setError(null);

        try {
            await makeAuthenticatedRequest(
                true,
                'post',
                `/ai/train/${user.id}`
            );
            pollTrainingStatus();
        } catch (error) {
            if (error?.response?.status === 409) {
                pollTrainingStatus();
            } else {
                handleError('Failed to start training');
            }
        }
    };

    const pollTrainingStatus = async () => {
        try {
            const response = await makeAuthenticatedRequest(
                true,
                'get',
                `/ai/training-status/${user.id}`
            );
            
            setModelStatus(response.data.status);

            if (response.data.status === 'Training in progress') {
                setTimeout(pollTrainingStatus, 30000);
            } else if (response.data.status === 'Model trained and available') {
                setLoading(false);
                analyzeFinances();
            } else {
                setLoading(false);
                handleError('Training failed');
            }
        } catch (error) {
            handleError('Error checking training status');
        }
    };

    const analyzeFinances = async () => {
        try {
            const response = await makeAuthenticatedRequest(
                true,
                'post',
                `/ai/analyze/${user.id}`
            );
            
            setAnalysis(response.data.analysis);
        } catch (error) {
            handleError('Failed to analyze finances');
        }
    };

    const handleResetModel = async () => {
        try {
            await makeAuthenticatedRequest(
                true,
                'post',
                `/ai/reset/${user.id}`
            );
            setModelStatus('Model not trained yet');
            setAnalysis(null);
            handleTrainAI();
        } catch (error) {
            handleError('Failed to reset model');
        }
    };

    const handleError = (message) => {
        setError(message);
        showModal?.(message);
        setLoading(false);
    };

    if (!user?.id) return null;

    return (
        <div className="ai-container">
            <div className="header-section">
                <h2>Financial AI Analysis</h2>
                <button 
                    className="reset-button"
                    onClick={handleResetModel}
                    disabled={loading}
                >
                    <span className={loading ? 'animate-spin' : ''}>↻</span>
                    <span>Reset Model</span>
                </button>
            </div>

            {error && <div className="error-message">{error}</div>}

            {loading && (
                <div className="status-message">
                    Training AI Model...
                </div>
            )}

            {modelStatus === 'Model not trained yet' && !loading && (
                <div className="start-section">
                    <p>Your financial AI model needs to be trained.</p>
                    <button 
                        className="train-button"
                        onClick={handleTrainAI}
                        disabled={loading}
                    >
                        Start Training
                    </button>
                </div>
            )}

            {analysis && (
                <div className="analysis-results">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Risk Assessment</h3>
                        </div>
                        <div className="risk-assessment">
                            <div className="risk-score">
                                Risk Score: {(analysis.riskScore * 100).toFixed(1)}%
                            </div>
                            <div>
                                Status: 
                                <span className={`status-badge ${analysis.status}`}>
                                    {analysis.status.replace('_', ' ')}
                                </span>
                            </div>
                        </div>
                    </div>

                    {analysis.recommendations?.length > 0 && (
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Recommendations</h3>
                            </div>
                            <div className="recommendations">
                                {analysis.recommendations.map((rec, index) => (
                                    <div key={index} className={`recommendation ${rec.type}`}>
                                        {rec.message}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Financial Metrics</h3>
                        </div>
                        <div className="metrics-grid">
                            <div className="metric-item">
                                <span className="metric-label">Credit Utilization:</span>
                                <span className="metric-value">
                                    {(analysis.details.utilizationRate * 100).toFixed(1)}%
                                </span>
                            </div>
                            <div className="metric-item">
                                <span className="metric-label">Savings Rate:</span>
                                <span className="metric-value">
                                    {(analysis.details.savingsRate * 100).toFixed(1)}%
                                </span>
                            </div>
                            <div className="metric-item">
                                <span className="metric-label">Monthly Change:</span>
                                <span className={`metric-value ${analysis.details.monthOverMonthChange >= 0 ? 'positive' : 'negative'}`}>
                                    ${Math.abs(analysis.details.monthOverMonthChange).toFixed(2)}
                                </span>
                            </div>
                            <div className="metric-item">
                                <span className="metric-label">Emergency Fund:</span>
                                <span className={`metric-value ${analysis.details.emergencyFundStatus === 'Adequate' ? 'positive' : 'warning'}`}>
                                    {analysis.details.emergencyFundStatus}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AI;